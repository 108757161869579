<template>
  <lazy-component
    ref="videoRoot"
    class="video-lazy-wrapper"
    :class="[ratio ? `ratio ratio-${ratio}` : '', { 'full-height': fullHeight }]"
    @intersected="onShow"
  >
    <div
      ref="videoWrapper"
      class="video-wrapper"
      :class="{ initialized: !!player, 'non-interactive': !interactive }"
      @click="play"
      @touchstart="play"
    >
      <video
        :id="'video-container-' + id"
        ref="videoPlayer"
        class="video-js"
        :class="{ 'no-preview': !hasPreview }"
        preload="auto"
        :poster="!source ? poster : null"
        crossorigin="anonymous"
        playsinline
      >
        <source v-if="source" :src="source" :type="sourceType" />
        <track
          v-for="subtitle in subtitles"
          :key="subtitle.lang"
          kind="subtitles"
          :src="subtitle.src"
          :srclang="subtitle.lang"
          :label="subtitle.label"
          :default="lang === subtitle.lang ? 'true' : false"
        />
      </video>

      <slot />
    </div>
  </lazy-component>
</template>

<script>
import { getMediaUrl } from "@/utils/mediaHelper";
import { amplitudeVideoTrack } from "@/utils/amplitude";
import { ENV_PRODUCTION } from "@/utils/variables";
import videojs from "video.js";
import "videojs-quality-selector-hls";
import "videojs-mux";
import "videojs-mobile-ui";
import "videojs-hotkeys";
import { insertScript } from "@/utils/domHelper";
import seekButtons from "@/utils/playerHelpers/seekButtons";
import { mapGetters } from "vuex";

export default {
  name: "base-player",
  props: {
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
    interactive: {
      type: Boolean,
      required: false,
      default: true,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false,
    },
    source: {
      type: String,
      required: false,
      default: null,
    },
    sourceType: {
      type: String,
      required: false,
      default: "application/x-mpegURL",
    },
    poster: {
      type: String,
      default: null,
    },
    subtitlesEn: {
      type: String,
      required: false,
      default: null,
    },
    subtitlesDe: {
      type: String,
      required: false,
      default: null,
    },
    subtitlesFr: {
      type: String,
      required: false,
      default: null,
    },
    ratio: {
      type: String,
      default: "",
      validator(value) {
        return ["", "16x9", "4x3"].includes(value);
      },
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    trackPlayback: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["play", "ready", "sendPlayback"],
  data() {
    return {
      player: null,
      currentTextTrackIndex: null,
      started: false,
      log: {
        timerStart: 0,
        currentTime: 0,
        previousTime: 0,
        playbackSent: false,
      },
    };
  },
  computed: {
    ...mapGetters("video", { activeVideo: "getActiveVideo" }),
    lang() {
      return this.$root.$i18n.locale;
    },
    subtitles() {
      const subtitles = [];

      if (!this.interactive) {
        return subtitles;
      }

      if (this.subtitlesEn) {
        subtitles.push({ label: "English", lang: "en", src: this.getSubtitles(this.subtitlesEn) });
      }

      if (this.subtitlesFr) {
        subtitles.push({ label: "French", lang: "fr", src: this.getSubtitles(this.subtitlesFr) });
      }

      if (this.subtitlesDe) {
        subtitles.push({ label: "Deutsch", lang: "de", src: this.getSubtitles(this.subtitlesDe) });
      }

      return subtitles;
    },
  },
  watch: {
    activeVideo(newVal, oldVal) {
      if (oldVal === this.id && this.player) {
        this.player.pause();
        this.sendPlayback();
      }
    },
    currentTextTrackIndex(val) {
      if (!window.cast) {
        return;
      }

      const mediaSession = window.cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession();

      if (!mediaSession) {
        return;
      }

      const tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(val === null ? [] : [val]);
      mediaSession.editTracksInfo(tracksInfoRequest, null, null);
    },
  },
  methods: {
    async play() {
      if (!this.source) {
        return;
      }

      if (this.id !== null && !this.started) {
        amplitudeVideoTrack(`Video play click`, this.id);
      }

      this.$emit("play");

      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.focus({ preventScroll: true });
      }
    },
    getQualityFromButton(el) {
      const numberPattern = /\d+/g;
      const qualityText = el.querySelector(".vjs-menu-item-text").innerText;
      return qualityText === "Auto" ? qualityText.toLowerCase() : parseInt(qualityText.match(numberPattern).join(""));
    },
    load() {
      if (this.player || !this.$refs.videoPlayer) {
        return;
      }

      const seekStep = 10;

      let options = {
        controls: true,
        errorDisplay: false,
        disablePictureInPicture: true,
        techOrder: ["html5"],
        plugins: {
          hotkeys: {
            volumeStep: 0.1,
            seekStep: seekStep,
            enableModifiersForNumbers: false,
            enableVolumeScroll: false,
          },
          mobileUi: {
            fullscreen: {
              enterOnRotate: true,
              lockOnRotate: !videojs.browser.IS_IOS,
              lockToLandscapeOnEnter: !videojs.browser.IS_IOS,
            },
          },
        },
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          vhs: {
            overrideNative: true,
            handlePartialData: true,
          },
        },
        controlBar: {
          pictureInPictureToggle: false,
        },
        poster: this.poster,
      };

      if (videojs.getPlugin("chromecast") !== undefined && window.cast) {
        options = this.setChromecastOptions(options);
      }

      if (process.env.VUE_APP_MUX_KEY && this.interactive) {
        options = this.setMuxOptions(options);
      }

      this.player = videojs(this.$refs.videoPlayer, options, () => {
        this.player.volume(this.volume);
        this.$nextTick(() => {
          const touchOverlay = this.$refs.videoWrapper.querySelector(".vjs-touch-overlay");

          if (touchOverlay) {
            const observer = new MutationObserver(() => {
              if (touchOverlay.classList.length === 1 && !this.player.paused()) {
                this.player.controlBar.addClass("hide");
              } else {
                this.player.controlBar.removeClass("hide");
              }
            });

            observer.observe(touchOverlay, {
              attributes: true,
              attributeFilter: ["class"],
              childList: false,
              characterData: false,
            });
          }

          seekButtons(this.player, seekStep);

          this.$emit("ready", this.player);
        });
      });

      if (this.player.airPlay !== undefined) {
        this.player.airPlay();
      }

      if (this.player.chromecast !== undefined) {
        this.player.chromecast();
      }

      this.player.one("play", async () => {
        this.started = true;
        amplitudeVideoTrack("video starts playing", this.id);
      });

      this.player.on("play", async () => {
        this.log.playbackSent = false;
        this.log.timerStart = this.player.currentTime();
        window.addEventListener("beforeunload", this.sendPlayback);

        this.$store.dispatch("video/setActiveVideo", {
          videoId: this.id,
        });
      });

      this.player.on("texttrackchange", () => {
        const textTracks = this.player.textTracks();
        const subtitleTracks = [];

        for (let i = 0; i < textTracks.length; i++) {
          if (textTracks[i].kind !== "subtitles") {
            continue;
          }

          subtitleTracks.push(textTracks[i]);
        }

        for (let i = 0; i < subtitleTracks.length; i++) {
          if (subtitleTracks[i].mode === "showing") {
            this.currentTextTrackIndex = i;
            return;
          }
        }

        this.currentTextTrackIndex = null;
      });

      if (!videojs.browser.IS_IOS) {
        const qualitySelector = this.player.qualitySelectorHls({ displayCurrentQuality: true });

        this.player.one("loadedmetadata", () => {
          let currentQualityButton;
          const quality = this.quality !== "auto" ? parseInt(this.quality) : "auto";
          this.$refs.videoWrapper.querySelectorAll(".vjs-quality-selector .vjs-menu-item").forEach((el) => {
            if (quality === this.getQualityFromButton(el)) {
              currentQualityButton = el;
            }

            el.addEventListener("click", (ev) => {
              const quality = this.getQualityFromButton(ev.currentTarget);
              this.$store.dispatch("player/setQuality", { quality });
            });
          });

          if (!currentQualityButton) {
            return;
          }

          this.$refs.videoWrapper.querySelector(".vjs-quality-selector .vjs-selected").classList.remove("vjs-selected");
          currentQualityButton.classList.toggle("vjs-selected", true);
          qualitySelector.setQuality(quality);
        });
      }

      this.player.on("timeupdate", () => {
        this.log.previousTime = this.log.currentTime;
        this.log.currentTime = this.player.currentTime();
      });

      this.player.on("volumechange", () => {
        const volume = this.player.muted() ? 0 : parseFloat(this.player.volume());
        this.$store.dispatch("player/setVolume", { volume });
      });

      this.player.on("seeking", () => {
        if (!this.log.playbackSent) {
          this.sendPlayback();
        }
      });

      this.player.on("ended", () => {
        this.sendPlayback();
      });

      if (this.autoplay && this.player.paused()) {
        setTimeout(() => {
          this.player.play().catch(() => {});
        }, 100);
      }
    },
    sendPlayback() {
      if (!this.trackPlayback) {
        return;
      }

      if (this.log.playbackSent) {
        return;
      }

      const duration = this.log.previousTime - this.log.timerStart;

      if (Math.floor(duration) <= 0) {
        return;
      }

      this.$emit("sendPlayback", {
        videoId: this.id,
        duration: duration,
        startDate: Math.floor(Date.now() / 1000),
        timelineStart: this.log.timerStart,
      });
    },
    async onShow() {
      if (!this.source) {
        return;
      }

      videojs.log.warn = function () {};

      if (!window.videojs) {
        window.videojs = videojs;
      }

      await insertScript(getMediaUrl("assets/js", "silvermine-videojs-airplay.min.js"));
      await insertScript(getMediaUrl("assets/js", "silvermine-videojs-chromecast.min.js"));
      await insertScript("https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1");
      await insertScript(getMediaUrl("assets/js", "videojs-vr.js"));
      await this.$nextTick();
      this.load();
    },
    getSubtitles(name) {
      if (name === "") {
        return "";
      }

      name = name.split(".").slice(0, -1).join(".") + ".vtt";
      // todo: workaround for subtitles on ios due to cors problem
      const subtitleHost = process.env.VUE_APP_ENV === ENV_PRODUCTION ? location.origin : process.env.VUE_APP_MEDIA_CONTENT_HOST;
      return `${subtitleHost}/content/subtitles/${name}`;
    },
    setChromecastOptions(options) {
      options.techOrder.unshift("chromecast");
      options.chromecast = {
        modifyLoadRequestFn: (loadRequest) => {
          const tracks = [];

          for (let i = 0; i < this.subtitles.length; i++) {
            const track = new window.chrome.cast.media.Track(i, window.chrome.cast.media.TrackType.TEXT);
            track.trackContentId = this.subtitles[i].src;
            track.trackContentType = "text/vtt";
            track.subtype = window.chrome.cast.media.TextTrackType.CAPTIONS;
            track.name = this.subtitles[i].label;
            track.language = this.subtitles[i].lang;
            tracks.push(track);
          }

          loadRequest.activeTrackIds = this.currentTextTrackIndex !== null ? [this.currentTextTrackIndex] : [];
          loadRequest.media.tracks = tracks;

          return loadRequest;
        },
      };
      options.plugins.chromecast = {};

      return options;
    },
    setMuxOptions(options) {
      options.plugins.mux = {
        debug: false,
        minimumRebufferDuration: 250,
        data: {
          env_key: process.env.VUE_APP_MUX_KEY,
          viewer_user_id: this.profile?.id || "",
          video_title: this.id,
          player_init_time: Date.now(),
        },
      };

      return options;
    },
  },
  beforeUnmount() {
    if (this.player && this.id && this.id !== this.activeVideo) {
      this.player.dispose();
    }

    window.removeEventListener("beforeunload", this.sendPlayback);

    this.sendPlayback();
  },
};
</script>

<!-- todo: move to public once fully switched -->
<style>
@import "https://cdn-cf.ersties.com/assets/css/videojs-vr.min.css";
</style>

<style lang="scss">
@import "video.js/dist/video-js.css";
@import "videojs-mobile-ui/dist/videojs-mobile-ui.css";
@import "@/assets/silvermine/silvermine-videojs-chromecast.css";
@import "@/assets/silvermine/silvermine-videojs-airplay.css";
@import "./index";
</style>
